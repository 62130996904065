import { Theme } from 'theme-ui'

export const theme: Theme = {
  colors: {
    primary: '#1DA57A',
    bg: '#f0f2f5'
  },
  text: {
    default: {
      my: 'auto',
      fontSize: ['14px', null, null, '16px', '16px'],
      lineHeight: ['14px', null, null, '20px', '20px']
    },
    mediumSmall: {
      my: 'auto',
      fontSize: ['12px', null, null, '14px', '14px'],
      lineHeight: ['14px', null, null, '18px', '18px']
    },
    small: {
      fontFamily: 'body',
      fontSize: ['10px', null, null, '12px', '12px'],
      lineHeight: ['12px', null, null, '16px', '16px']
    },
    large: {
      fontFamily: 'body',
      fontSize: ['16px', null, null, '18px', '18px'],
      lineHeight: ['18px', null, null, '20px', '20px']
    },
    extraLarge: {
      fontFamily: 'body',
      fontSize: ['20px', null, null, '18px', '24px'],
      lineHeight: ['20px', '20px', null, '20px', '24px']
    },
    mediumTitle: {
      letterSpacing: '0.08em',
      fontWeight: 'heading',
      fontFamily: 'body',
      fontSize: ['13px', null, null, '18px', '18px'],
      lineHeight: ['16px', '20px', null, '20px', '24px']
    },
    largeTitle: {
      letterSpacing: '0.05em',
      fontWeight: 'heading',
      fontFamily: 'Poppins',
      fontSize: ['13px', '17px', '20px', '24px', '32px'],
      lineHeight: ['16px', '24px', '28px', '32px', '36px']
    }
  },
  styles: {
    hr: {
      bg: 'bg',
      border: 0,
      height: '2px'
    }
  },
  layout: {
    sidebarItem: {
      p: 3,
      alignItem: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: [50, 100, 150, 200],
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer'
    }
  }
}
