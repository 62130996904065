import { IDataResponse } from './../request'

export interface ProductVariant {
  id: number
  variant_name: string
  sku: string
  amazon_sku?: string
  shopify_sku?: string
  product_id: number
  price: number
  barcode?: string
  weight: number
  option_1: string
  option_2?: string
  option_3?: string
  shopify_id?: string
  shopify_stock_id?: string
  shopify_location_id?: string
  created_at: string
  updated_at: string
  availability: boolean
  // "stocks": [

  // ],
  fullname: string
}

// useSWR, return strings
const getAll = '/variants'

// useSWR, return strings
const get = (id: number | string) => {
  return `/variants/${id}`
}

// processing
const toRow = (data: IDataResponse<ProductVariant>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<ProductVariant>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const ProductVariantService = {
  getAll,
  get,
  toPaginate,
  toRow
}

export default ProductVariantService
