import { Button, Card, Col, Form, Input, message, Row, Space, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FC, useState } from 'react'
import { Box } from 'theme-ui'
import FeedbackService, { CreateFeedback } from '../../network/services/feedback'

const CustomerFeedbackPage: FC = () => {
  const [form] = Form.useForm<CreateFeedback>()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (data: CreateFeedback) => {
    setLoading(true)
    try {
      const { data: response } = await FeedbackService.create(data)
      if (response.success) {
        message.success('successfully create a ticket')
      }
    } catch (e: any) {
      message.error(e.message)
    }
    setLoading(false)
  }

  return (
    <Row justify="center" align="middle" style={{ height: '100vh', width: '100%' }}>
      <Col>
        <Space direction="vertical">
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography.Title>Casefinite</Typography.Title>
            <Typography.Paragraph>Customer Service</Typography.Paragraph>
          </div>

          <Card style={{ minWidth: '600px' }}>
            <Form autoComplete="off" form={form} onFinish={handleSubmit}>
              <h3>Email</h3>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email'
                  }
                ]}
              >
                <Input size="large" placeholder="Email" autoComplete="off" />
              </Form.Item>

              <Box p={1} />
              <h3>Name</h3>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name'
                  }
                ]}
              >
                <Input size="large" placeholder="First name" autoComplete="off" />
              </Form.Item>

              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name'
                  }
                ]}
              >
                <Input size="large" placeholder="Last name" autoComplete="off" />
              </Form.Item>

              <Box p={1} />
              <h3>Title</h3>
              <Form.Item name="title">
                <Input size="large" placeholder="Title" autoComplete="off" />
              </Form.Item>

              <Box p={1} />
              <h3>Description</h3>
              <Form.Item
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a description'
                  }
                ]}
              >
                <TextArea size="large" placeholder="Description" autoComplete="off" rows={8} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

export default CustomerFeedbackPage
