import { ReactNode, useMemo } from 'react'
import { DashboardOutlined, FileTextOutlined } from '@ant-design/icons'

import authStore from '../lib/store/auth'
import { useSnapshot } from 'valtio'

// super admin 0
// admin 1
// operator 2

const useSidebarOption = () => {
  const { profile: admin } = useSnapshot(authStore)
  //   const { t } = useTranslation('translation')
  const options = useMemo<
    {
      key: string
      label: string
      icon: ReactNode
    }[]
  >(() => {
    if (!admin) return []
    const options: {
      key: string
      label: string
      icon: ReactNode
    }[] = [
      {
        key: '/admin',
        label: 'Dashboard',
        icon: <DashboardOutlined />
      },
      {
        key: '/admin/feedback-templates',
        label: 'Message Templates',
        icon: <FileTextOutlined />
      }
    ]
    return options
  }, [admin])

  return options
}

export default useSidebarOption
