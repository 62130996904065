import { FC, useEffect, useMemo } from 'react'
// import { Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { useNavigate } from 'react-router-dom'
import { CommonData, ProductData, OrderData } from '../../lib/store/templateData'
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  Collapse,
  Typography
} from 'antd'
import Gaps from '../../components/Gaps'
import { useState } from 'react'
import FeedbackTemplateService, {
  CreateFeedbackTemplate,
  FeedbackTemplate
} from '../../network/services/feedbackTemplate'
import { useWatch } from 'antd/lib/form/Form'
import { ReactNode } from '@mdx-js/react/lib'

const FeedbackTemplateForm: FC<{
  form?: FormInstance<CreateFeedbackTemplate>
  initialValue?: FeedbackTemplate
  isLoading?: boolean
  setIsLoading?: (isLoading: boolean) => any
  updateAllowed?: boolean
  createAllowed?: boolean
  readAllowed?: boolean
  deleteAllowed?: boolean
  templateTypes?: any[]
  fieldNames?: string[]
  refresh?: () => any
}> = ({
  form,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh = () => {},
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false,
  templateTypes = [],
  fieldNames = []
}) => {
  const navigate = useNavigate()
  const [isReadOnly, setIsReadOnly] = useState(false)
  // const [contentEditorState] = useState(() => EditorState.createEmpty())
  const content = useWatch(['content'], form)
  const templateTypeId = useWatch(['template_type_id'], form)

  const templateData = useMemo(() => {
    if (templateTypeId === 1) return { title: 'Order', data: OrderData }
    if (templateTypeId === 2) return { title: 'Product', data: ProductData }
    return null
  }, [templateTypeId])

  const tags = useMemo(() => {
    if (!content) return []
    return (content.match(/\{\{[^}{]+\}\}/g) as string[]) ?? []
  }, [content])

  const highlightedContent = useMemo<ReactNode[]>(() => {
    if (!content) return []
    //TODO: switch the data type here
    var dataFields = [...CommonData]
    if (templateData) dataFields = [...dataFields, ...templateData.data]
    const regex =
      tags.length > 0 ? new RegExp(`(?=${tags.join('|')})|(?<=${tags.join('|')})`) : null
    // var splittedContent: ReactNode[] = content.split(/(?=[\s])|(?<=[\s])/g)
    var splittedContent: string[] = content.split(regex ?? /(?=[\s])|(?<=[\s])/g)
    var finalContent: ReactNode[] = []
    for (const str of splittedContent) {
      if (regex && str.match(regex)) {
        const varName = str.replace(/\{|\}/g, '')
        const sample = dataFields.find((item) => item.name === varName)
        finalContent.push(
          <span style={{ color: sample ? 'blue' : 'red' }}>{sample ? sample.sample : str}</span>
        )
        continue
      }
      if (str.match('\n')) {
        var arrWithBr: ReactNode[] = str.split(/(?=[\n])|(?<=[\n])/g)
        for (var i = 0; i < arrWithBr.length; i++) {
          if (arrWithBr[i] === '\n') arrWithBr[i] = <br />
        }
        finalContent.push(...arrWithBr)
        continue
      }
      finalContent.push(str)
    }
    return finalContent
  }, [content, tags, templateData])

  // useEffect(() => {
  //   //check the access level
  //   if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
  //     navigate('/events')
  //   }
  // })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  // const handleContentChange = (e: EditorState) => {
  //   // setTemplateContent(e)
  //   console.log(e)
  // }

  // const handleKeyCommand = (command: string, editorState: EditorState) => {
  //   const newState = RichUtils.handleKeyCommand(editorState, command)

  //   if (newState) {
  //     handleContentChange(newState)
  //     return 'handled'
  //   }
  //   return 'not-handled'
  // }

  const handleSubmit = async (values: CreateFeedbackTemplate) => {
    if (isLoading) return
    setIsLoading && setIsLoading(true)

    if (initialValue?.id) {
      // update
      try {
        const { data: result } = await FeedbackTemplateService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error: any) {
        message.error('Update template error ' + error.message)
      }
    } else {
      // new
      try {
        const { data: result } = await FeedbackTemplateService.create(values)
        if (result.success) {
          console.log(result.data)
          message.success('Create successful')
          navigate(`/admin/feedback-templates/${result.data.id}`)
        }
      } catch (error: any) {
        message.error('Create template error ' + error.message)
      }
    }

    setIsLoading && setIsLoading(false)
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col>
          <Collapse>
            <Collapse.Panel header="Common variables" key="1">
              {CommonData.map((item) => (
                <>
                  <div>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{item.name}</Typography.Text>
                  </div>
                </>
              ))}
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col>
          {templateData && (
            <Collapse>
              <Collapse.Panel header={`${templateData.title} variables`} key="2">
                {templateData.data.map((item) => (
                  <>
                    <div>
                      <Typography.Text style={{ fontWeight: 'bold' }}>{item.name}</Typography.Text>
                    </div>
                  </>
                ))}
              </Collapse.Panel>
            </Collapse>
          )}
        </Col>
      </Row>
      <Gaps />
      <Form
        form={form}
        name="new-template"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="template_type_id"
                label="Template type"
                rules={[{ required: true, message: 'Please select a template type' }]}
              >
                <Select>
                  {templateTypes?.length > 0 &&
                    templateTypes.map((templateType) => (
                      <Select.Option value={templateType.id}>{templateType.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert quantity' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="content" label="Content">
                <Input.TextArea
                  style={{ padding: 10 }}
                  readOnly={isReadOnly}
                  autoSize={{ minRows: 4 }}
                  spellCheck={false}
                />
              </Form.Item>
              Preview
              <Card bodyStyle={{ padding: 10 }}>{highlightedContent.map((item) => item)}</Card>
              <Gaps />
              {/* <Card>
                <Editor
                  editorState={contentEditorState}
                  handleKeyCommand={handleKeyCommand}
                  onChange={handleContentChange}
                  placeholder="Enter a template"
                />
              </Card> */}
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default FeedbackTemplateForm
