import client from '../request'

export type RegisterState = {
  email: string
  username: string
  password: string
  password_confirmation: string
  auth_code: string
  referral_code?: string
  phone?: string
  full_name: string
  gender: string
  nationality: string
  birthdate: string
  has_agreed: boolean
}

export type LoginState = {
  email: string
  password: string
}

export type ResetPassword = {
  password: string
  new_password: string
  new_password_confirmation: string
}

export type ForgotPassword = {
  email: string
  password: string
  password_confirmation: string
  auth_code: string
}

const login = (data: LoginState) => {
  return client.post('/auth/login', data)
}

const logout = () => {
  return client.post('/auth/logout')
}

const AuthService = {
  login,
  logout
}

export default AuthService
