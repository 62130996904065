import React, { FC } from 'react'
import { Layout, Menu, Row, Space, Typography } from 'antd'
import { useSnapshot } from 'valtio'
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons'
import { Link, Outlet } from 'react-router-dom'

import AuthService from '../network/services/auth'
import globalStore from '../lib/store/global'
import { logout } from '../lib/store/auth'
import useSidebarOption from '../hooks/useSidebarOption'

const { Header, Footer, Sider, Content } = Layout

const AuthenticatedLayout: FC = () => {
  const options = useSidebarOption()
  const { collapsed } = useSnapshot(globalStore)

  const toggle = () => {
    globalStore.collapsed = !collapsed
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Space
          className="sider__space"
          direction="vertical"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div style={{ minHeight: 60 }} className="flex-center">
            <Link to="/admin">
              {collapsed ? (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  C
                </Typography.Title>
              ) : (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Customer Service
                </Typography.Title>
              )}
            </Link>
          </div>

          <Menu
          // openKeys={isCollapsed ? [] : openKeys}
          // selectedKeys={current}
          // onOpenChange={onOpenChange}
          >
            {options.map((option) => {
              return (
                <Menu.Item key={option.key}>
                  <Link to={`${option.key}`}>
                    {option.icon}
                    <span>{option.label}</span>
                  </Link>
                </Menu.Item>
              )
            })}
          </Menu>
        </Space>
      </Sider>
      <Layout>
        <Header
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggle} />
          ) : (
            <MenuFoldOutlined onClick={toggle} />
          )}

          <LogoutOutlined
            onClick={async () => {
              const { data: result } = await AuthService.logout()
              if (result.success) {
                logout()
              }
            }}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
        <Footer>
          <Row>
            <Typography.Text style={{ margin: 'auto' }}>
              copyright @ {new Date().getFullYear()}
            </Typography.Text>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
