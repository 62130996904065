import { Button, Card, Col, Empty, Form, message, Row, Space, Spin, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { Flex } from 'theme-ui'
import DialogCell from '../../components/DialogCell'
import { getPublicFetcher } from '../../network/request'
import FeedbackService, { CreateFeedback, Feedback } from '../../network/services/feedback'

const CustomerReplyPage: FC = () => {
  const [form] = Form.useForm<CreateFeedback>()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const publicFetcher = getPublicFetcher(token ?? '')
  const { mutate } = useSWR<Feedback>(id && token ? FeedbackService.get(id) : null, publicFetcher)

  const handleSubmit = async (data: CreateFeedback) => {
    if (!id || !token) return
    setLoading(true)
    try {
      const { data: response } = await FeedbackService.customerCreateHistory(id, data, token)
      if (response.success) {
        message.success('successfully replied')
        await mutate()
      }
    } catch (e: any) {
      message.error(e.message)
    }
    setLoading(false)
  }

  return (
    <Row justify="center" align="middle" style={{ height: '100vh', width: '100%' }}>
      <Col>
        <Space direction="vertical">
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography.Title>Casefinite</Typography.Title>
            <Typography.Paragraph>Customer Service</Typography.Paragraph>
          </div>
          <HistoryList />
          <Card style={{ minWidth: '600px' }}>
            <Form autoComplete="off" form={form} onFinish={handleSubmit}>
              <h3>Reply</h3>
              <Form.Item
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a description'
                  }
                ]}
              >
                <TextArea size="large" autoComplete="off" rows={4} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

const HistoryList: FC = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  let date = ''
  const bottomRef = useRef<HTMLDivElement>(null)
  const token = useMemo(() => searchParams.get('token'), [searchParams])
  const publicFetcher = getPublicFetcher(token ?? '')
  const { data: feedback, error } = useSWR<Feedback>(
    id && token ? FeedbackService.get(id) : null,
    publicFetcher
  )

  useEffect(() => {
    if (feedback?.histories) {
      bottomRef?.current &&
        bottomRef.current.scrollIntoView({
          behavior: 'smooth'
        })
    }
  }, [feedback, bottomRef])

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!feedback) {
    return <Spin />
  }

  const histories = feedback.histories
  if (histories.length === 0) {
    return <Empty description="No record" />
  }

  return (
    <Card style={{ overflowY: 'scroll', height: '40vh' }}>
      {histories.map((item) => {
        const today = new Date()
        const dateNow = new Date(item.created_at)
        const dateNowStr = dateNow.toLocaleDateString()
        const sameDate = dateNowStr === date
        date = dateNowStr
        return (
          <>
            {!sameDate && (
              <Flex sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
                {dateNow.getFullYear() === today.getFullYear() &&
                dateNow.getMonth() === today.getMonth() &&
                dateNow.getDay() === today.getDay()
                  ? 'Today'
                  : dateNowStr}
              </Flex>
            )}
            <DialogCell history={item} />
          </>
        )
      })}
      <div ref={bottomRef}></div>
    </Card>
  )
}

export default CustomerReplyPage
