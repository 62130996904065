import { DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Typography } from 'antd'
import { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, Flex, Image } from 'theme-ui'
import Modal from 'react-modal'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { FeedbackUpload } from '../network/services/feedback'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const ImageModal: FC<{
  isOpen: boolean
  onRequestClose: () => any
  files: FeedbackUpload[]
  initialIndex: number
}> = ({ isOpen, onRequestClose, files, initialIndex }) => {
  const handleDownload = async (src: string, fileName: string) => {
    try {
      const { data } = await axios.get(src, {
        responseType: 'blob'
      })
      saveAs(data, fileName)
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          position: 'relative',
          width: '50%',
          height: '80%'
        },
        overlay: {
          background: '#00000099',
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99
        }}
      >
        <Swiper
          initialSlide={initialIndex}
          spaceBetween={50}
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          navigation
          pagination
        >
          {files.length > 0 &&
            files.map((item, index) => {
              const slicedFileName = item.file_name.split('.')
              const ext = slicedFileName[slicedFileName.length - 1]
              const isImage = ['png', 'jpg', 'jfif', 'webp'].includes(ext.toLowerCase())
              return (
                <SwiperSlide
                  style={{ width: 600, height: 600, paddingLeft: 3, paddingRight: 3 }}
                  key={index}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    {isImage ? (
                      <Image
                        src={item.url}
                        sx={{ objectFit: 'contain', width: 600, height: '100%' }}
                      />
                    ) : (
                      <Card
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          textAlign: 'center',
                          padding: 3,
                          background: 'white',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}
                      >
                        <Box sx={{ mb: 3 }}>
                          <Typography.Text>{item.client_file_name}</Typography.Text>
                        </Box>
                        <Button
                          onClick={() => handleDownload(item.url, item.file_name)}
                          type="primary"
                        >
                          Download <DownloadOutlined />
                        </Button>
                      </Card>
                    )}
                  </Flex>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </Box>
    </Modal>
  )
}
export default ImageModal
