import React, { Suspense } from 'react'
import './App.less'
import { useSnapshot } from 'valtio'
import authStore, { checkAuthorization } from './lib/store/auth'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Space, Spin } from 'antd'
import AuthenticatedLayout from './layout/AuthenticatedLayout'
import SignIn from './pages/auth/SignIn'
import FeedbackPage from './pages/feedback'
import CustomerFeedbackPage from './pages/customerFeedback'
import CustomerReplyPage from './pages/customerReply'
import FeedbackTemplatePage from './pages/feedbackTemplate'
import FeedbackTemplateDetail from './pages/feedbackTemplate/feedbackTemplateDetail'

function PrivateRoute({ children, redirectTo }: { children: JSX.Element; redirectTo: string }) {
  const { token: isLoggedIn } = useSnapshot(authStore)

  return isLoggedIn ? children : <Navigate to={redirectTo} />
}

function App() {
  return (
    <Suspense
      fallback={
        <Space align="center" style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}>
          <Spin />
        </Space>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="admin"
            element={
              <PrivateRoute redirectTo="/">
                <AuthenticatedLayout />
              </PrivateRoute>
            }
          >
            <Route path="" element={<FeedbackPage />} />

            <Route path="feedback-templates" element={<FeedbackTemplatePage />} />
            <Route path="feedback-templates/:id" element={<FeedbackTemplateDetail />} />
          </Route>
          <Route path="/" element={<SignIn />} />
          <Route path="customer-feedback" element={<CustomerFeedbackPage />} />
          <Route path="customer-reply/:id" element={<CustomerReplyPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

new Promise(() => {
  checkAuthorization()
}).catch((error) => console.error(error))

export default App
