import {
  Button,
  Empty,
  Input,
  message,
  Spin,
  Form,
  Space,
  Typography,
  Modal,
  Select,
  Card,
  Upload,
  Collapse
} from 'antd'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { createContext, FC, UIEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'
import { AspectRatio, Box, Divider, Flex, Grid, Image, Text } from 'theme-ui'
import FeedbackService, {
  Feedback,
  FeedbackHistory,
  FeedbackUpload
} from '../../network/services/feedback'
import CustomerService, { Customer } from '../../network/services/customer'
import toUTCTime from '../../lib/modules/to-utc-time'
import { useForm } from 'antd/lib/form/Form'
import {
  PhoneOutlined,
  MailOutlined,
  FileTextOutlined,
  DownloadOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons'
import OrderService from '../../network/services/order'
import { IDataResponse, serialize } from '../../network/request'
import Gaps from '../../components/Gaps'
import DialogCell from '../../components/DialogCell'
import FeedbackTemplateService from '../../network/services/feedbackTemplate'
import ProductVariantService from '../../network/services/productVariant'
import ImageModal from '../../components/ImageModal'
import axios from 'axios'
import { saveAs } from 'file-saver'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useSearchParams } from 'react-router-dom'

const feedbackContext = createContext<{
  id?: number | string
  setId?: (id: number | string) => any
  customerEmail?: string
}>({
  id: undefined,
  setId: undefined,
  customerEmail: undefined
})

const FeedbackPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  // const [id, setId] = useState<number | string>()
  const [customerEmail, setCustomerEmail] = useState<string>()
  const id = useMemo(() => {
    return searchParams.get('id') ?? undefined
  }, [searchParams])

  const setId = (id: string | number) => {
    setSearchParams(`id=${id}`, { replace: true })
  }

  return (
    <feedbackContext.Provider value={{ id, setId, customerEmail }}>
      <Grid columns={[3, '1fr 2fr 1fr']} gap={4} sx={{ width: 'auto', m: 3, height: [700, 900] }}>
        <FeedbackListWrapper />
        <FeedbackDetails setCustomerEmail={setCustomerEmail} />
        <CustomerDetailsWrapper />
      </Grid>
    </feedbackContext.Provider>
  )
}

const FeedbackListWrapper: FC = () => {
  // const [error, setError] = useState<any>()
  const [feedbackLimit, setFeedbackLimit] = useState(10)
  const { data: feedbackData, error } = useSWR(
    serialize('/feedbacks', {
      limit: 20
    })
  )
  const [data, setData] = useState<IDataResponse<Feedback>>()
  const [feedbackRows, setFeedbackRows] = useState<Feedback[]>([])
  const { sendMessage, readyState } = useWebSocket(FeedbackService.wsGetAll, {
    onMessage: (event) => {
      const getJson = async () => {
        if (event?.data) {
          try {
            const blob = event.data as Blob
            const td = new TextDecoder('utf-8')
            const text = td.decode(await blob.arrayBuffer())
            const newData = JSON.parse(text) as Feedback
            if (newData) {
              var tmpDataRows = feedbackRows.filter(
                (item) => item.id.toString() !== newData.id.toString()
              )
              setFeedbackRows(
                [...tmpDataRows, newData].sort((a, b) => {
                  const dateA = new Date(a.updated_at)
                  const dateB = new Date(b.updated_at)
                  return dateB.getTime() - dateA.getTime()
                })
              )
            }
          } catch (e) {
            console.log('error when connecting to websocket')
            // setError(e)
          }
        }
      }
      getJson()
    },
    onError: (event) => {
      console.log('error when connecting to websocket')
      // setError('Failed to connected to websocket server')
    },
    reconnectInterval: 1000,
    reconnectAttempts: 120,
    retryOnError: true,
    onReconnectStop: (numAttempts) => {
      console.log(`stopped reconnecting to the server after ${numAttempts} attempts`)
    }
  })
  const { total } = data ? FeedbackService.toPaginate(data) : { total: 0 }

  useEffect(() => {
    if (readyState === ReadyState.CONNECTING) {
      sendMessage(JSON.stringify({ action: 'subscribe' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState])

  useEffect(() => {
    if (feedbackData) {
      setData(feedbackData)
      setFeedbackRows(FeedbackService.toRow(feedbackData))
    }
  }, [feedbackData])

  //detect scroll to end
  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const maxHeight = e.currentTarget.scrollHeight
    const currentBottom = e.currentTarget.scrollTop + e.currentTarget.offsetHeight
    if (feedbackLimit >= total) return
    if (currentBottom >= maxHeight) {
      setFeedbackLimit((prev) => (prev += 10))
    }
  }

  return (
    <Box
      sx={{
        height: [700, 900],
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white'
        // scrollbarWidth: 'none',
        // //for firefox
        // '::-webkit-scrollbar': {
        //   display: 'none'
        // }
      }}
    >
      <Box sx={{ p: 3, pb: 0 }}>
        <h3>Feedbacks</h3>
        <Divider />
      </Box>
      <Box
        onScroll={onScroll}
        sx={{ flex: 1, overflowY: 'scroll', height: '100%', p: 3, backgroundColor: 'white', pt: 0 }}
      >
        <FeedbackList limit={feedbackLimit} error={error} data={data} dataRow={feedbackRows} />
      </Box>
    </Box>
  )
}

const FeedbackList: FC<{
  limit: number
  error?: any
  data?: IDataResponse<Feedback>
  dataRow: Feedback[]
}> = ({ error, data, dataRow }) => {
  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!data) {
    return <Spin />
  }

  return (
    <>
      {dataRow.length < 0 ? (
        <Empty />
      ) : (
        dataRow.map((item, index) => {
          return (
            <div key={index}>
              <FeedbackCard feedback={item} />
              <Divider />
            </div>
          )
        })
      )}
    </>
  )
}

const FeedbackDetails: FC<{
  setCustomerEmail: (customerEmail: string) => any
}> = ({ setCustomerEmail }) => {
  const { id } = useContext(feedbackContext)
  const { mutate: feedbackListMutate } = useSWR<{ data: Feedback[] }>(FeedbackService.getAll)
  const [isLoading, setIsLoading] = useState(false)
  const [modalDisplay, setModalDisplay] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploadCollapse, setUploadCollapse] = useState(true)
  const [form] = useForm<{
    content: string
  }>()

  const { data: feedback, error } = useSWR<Feedback>(id ? FeedbackService.get(id) : null)
  // const { mutate: historyMutate } = useSWR(id ? FeedbackService.getHistory(id) : null)

  useEffect(() => {
    if (feedback) {
      setCustomerEmail(feedback.email)
    }
  }, [feedback, setCustomerEmail])

  if (!id) {
    return <Empty description="Select a feedback ticket to view" />
  }

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!feedback) {
    return <Spin />
  }

  const handleChooseTemplate = () => {
    setModalDisplay(true)
  }

  const onTemplateResult = (content: string) => {
    form.setFieldsValue({
      content
    })
  }

  const handleFilePreview = (file: UploadFile) => {}
  const handleFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handleSend = async (data: { content: string }) => {
    setIsLoading(true)
    const formData = new FormData()
    fileList.forEach((file, index) => {
      console.log(file)
      if (file?.originFileObj) {
        formData.append(`attachments[${index}]`, file.originFileObj)
      }
    })
    formData.append('content', data.content)
    try {
      const { data: result } = await FeedbackService.createHistory(id, formData)
      if (result.success) {
        feedbackListMutate()
        form.setFieldsValue({
          content: ''
        })
        setFileList([])
        // historyMutate()
      }
    } catch (e) {
      message.error('message could not be sent' + e)
    }
    setIsLoading(false)
  }

  return (
    <>
      <TemplateModal
        visible={modalDisplay}
        onCancel={() => setModalDisplay(false)}
        onResult={onTemplateResult}
      />
      <Box
        sx={{
          height: [700, 900],
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ position: 'absolute', width: '100%', zIndex: 0 }}>
          <Collapse bordered={false}>
            <Collapse.Panel
              key="1"
              header={
                <Box>
                  <h3 style={{ fontWeight: 1000 }}>
                    {feedback.title === '' ? '<No title>' : feedback.title}
                  </h3>{' '}
                  <Text color="grey" variant="mediumSmall">
                    {feedback.first_name} {feedback.last_name}
                  </Text>
                </Box>
              }
            >
              <Box
                sx={{
                  flex: 1,
                  p: 3,
                  pb: 0
                }}
              >
                <Box>
                  <Text color="grey" variant="mediumSmall">
                    {feedback.email}
                  </Text>
                </Box>
                <Box>
                  <Text color="grey" variant="mediumSmall">
                    Ticket ID : {feedback.id}
                  </Text>
                </Box>
                <Box>
                  <Text color="grey" variant="mediumSmall">
                    Ticket created at : {toUTCTime(feedback.created_at)}
                  </Text>
                </Box>
                <Box p={2} />
                <Box>
                  Feedback description :
                  <br />
                  <Text color="grey" variant="mediumSmall" as="p" sx={{ fontWeight: 'bold' }}>
                    {feedback.content}
                  </Text>
                </Box>
                {/* {feedback.uploads.map((item) => item.url)} */}
                {feedback.uploads.length > 0 && (
                  <>
                    <Box p={2} />
                    Attatchment :
                    <ChatUploadList files={feedback.uploads} />
                  </>
                )}
              </Box>
            </Collapse.Panel>
          </Collapse>
        </Box>

        <Box sx={{ height: 200 }} />
        <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll', p: 3, pt: 0 }}>
          <HistoryList id={id} feedback={feedback} />
        </Box>

        <Box sx={{ bg: '#e2e2e2', p: 3, pt: 3, pb: 0 }}>
          <Box sx={{ width: '100%' }}>
            <Flex sx={{ width: '100%', justifyContent: 'center' }}>
              <Button type="text" onClick={() => setUploadCollapse(!uploadCollapse)}>
                {uploadCollapse ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </Flex>
            <Box style={{ width: '100%', display: uploadCollapse ? 'none' : 'block' }}>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handleFilePreview}
                onChange={handleFileChange}
              >
                {fileList.length >= 8 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Box>
          </Box>

          <Form form={form} onFinish={handleSend} autoComplete="off" style={{ flex: 1 }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start'
              }}
            >
              <Form.Item
                style={{ flex: 1 }}
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'This field must not be empty'
                  }
                ]}
              >
                <Input.TextArea rows={3} disabled={isLoading} />
              </Form.Item>
              <Flex sx={{ flexDirection: 'column', ml: 1 }}>
                <Button
                  type="dashed"
                  style={{ marginBottom: 4 }}
                  onClick={handleChooseTemplate}
                  loading={isLoading}
                >
                  <FileTextOutlined />
                  Choose template
                </Button>
                <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                  Send
                </Button>
              </Flex>
            </Box>
          </Form>
        </Box>
      </Box>
    </>
  )
}

const ChatUploadList: FC<{ files: FeedbackUpload[] }> = ({ files }) => {
  const [imageModal, setImageModal] = useState(false)
  const [displayImageIndex, setDisplayImageIndex] = useState(0)

  const handleDispayOriginalImage = (index: number) => {
    setDisplayImageIndex(index)
    setImageModal(true)
  }

  const handleDownload = async (src: string, fileName: string) => {
    try {
      const { data } = await axios.get(src, {
        responseType: 'blob'
      })
      saveAs(data, fileName)
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <>
      <ImageModal
        files={files}
        isOpen={imageModal}
        onRequestClose={() => setImageModal(false)}
        initialIndex={displayImageIndex}
      />
      <Flex sx={{ width: '100%' }}>
        {files.slice(0, 4).map((item, index) => {
          const slicedFileName = item.file_name.split('.')
          const ext = slicedFileName[slicedFileName.length - 1]
          const isImage = ['png', 'jpg', 'jfif', 'webp'].includes(ext.toLowerCase())
          return (
            <Box
              sx={{
                borderRadius: 10,
                overflow: 'hidden',
                width: 50
              }}
            >
              <AspectRatio ratio={1}>
                {isImage ? (
                  index === 3 && files.length > 4 ? (
                    <Box
                      sx={{
                        backgroundImage: `url(${item.url})`,
                        width: '100%',
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleDispayOriginalImage(3)}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'black',
                          opacity: 0.4,
                          position: 'absolute'
                        }}
                      />
                      <Flex
                        sx={{
                          position: 'relative',
                          zIndex: 0,
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography.Title style={{ color: 'white', opacity: 1 }}>
                          {files.length - 3}+
                        </Typography.Title>
                      </Flex>
                    </Box>
                  ) : (
                    <Image
                      src={item.url}
                      alt={item.url}
                      sx={{ objectFit: 'cover', width: '100%', cursor: 'pointer' }}
                      onClick={() => handleDispayOriginalImage(index)}
                    />
                  )
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      p: 3,
                      background: 'white',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleDownload(item.url, item.file_name)}
                  >
                    <Flex
                      sx={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography.Text>{item.client_file_name}</Typography.Text>
                      <Box>
                        <DownloadOutlined />
                      </Box>
                    </Flex>
                  </Box>
                )}
              </AspectRatio>
            </Box>
          )
        })}
      </Flex>
    </>
  )
}

const TemplateModal: FC<{
  visible: boolean
  onCancel: () => any
  onResult: (content: string) => any
}> = ({ visible, onCancel, onResult }) => {
  const { id, customerEmail: email } = useContext(feedbackContext)
  const [templateSearch, setTemplateSearch] = useState('')
  const [dataSearch, setDataSearch] = useState('')
  const [templateSearchDebounce, setTemplateSearchDebounce] = useState<NodeJS.Timeout | undefined>()
  const [dataSearchDebounce, setDataSearchDebounce] = useState<NodeJS.Timeout | undefined>()
  const [form] = Form.useForm()
  const templateId = Form.useWatch(['template_id'], form)
  const { data: templateData } = useSWR(
    serialize(FeedbackTemplateService.getAll, {
      title: templateSearch
    })
  )

  const templates = FeedbackTemplateService.toRow(templateData)

  const dataType: {
    url: string
    name: string
  } | null = useMemo(() => {
    const templateTypeId = templates
      .find((item) => item.id === templateId)
      ?.template_type_id.toString()
    if (!templateTypeId) {
      return null
    }
    if (templateTypeId === '1') {
      return {
        url: serialize(OrderService.getAll, {
          customer_email: email
        }),
        name: 'Orders'
      }
    }
    if (templateTypeId === '2') {
      return {
        url: serialize(ProductVariantService.getAll, {
          search: dataSearch
        }),
        name: 'Products'
      }
    }
    return null
  }, [templateId, templates, dataSearch, email])

  const { data: extraData } = useSWR(dataType ? dataType.url : null)

  const extras: any[] = useMemo(() => {
    if (!extraData || !dataType) return []

    if (dataType.name === 'Orders') {
      return OrderService.toRow(extraData)
    }

    if (dataType.name === 'Products') {
      return ProductVariantService.toRow(extraData)
    }

    return []
  }, [dataType, extraData])

  const handleTemplateSearch = (value: string) => {
    clearTimeout(templateSearchDebounce)
    const debounce = setTimeout(() => {
      setTemplateSearch(value)
    }, 500)
    setTemplateSearchDebounce(debounce)
  }

  const handleDataSearch = (value: string) => {
    clearTimeout(dataSearchDebounce)
    const debounce = setTimeout(() => {
      setDataSearch(value)
    }, 500)
    setDataSearchDebounce(debounce)
  }

  const handleSubmit = async (values: any) => {
    if (!id) return
    console.log(values.data_id)
    try {
      const { data: response } = await FeedbackTemplateService.getPreview(values.template_id, {
        feedback_id: id,
        data_id: values.data_id
      })
      onCancel()
      onResult(response.preview)
    } catch (e: any) {
      message.error('failed to fetch template preview ' + e.message)
    }
  }
  return (
    <Modal visible={visible} onCancel={onCancel} okText="Confirm" onOk={() => form.submit()}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="template_id" label="Choose a template">
          <Select
            showSearch
            placeholder="Select a feedback template to proceed"
            onSearch={handleTemplateSearch}
            filterOption={false}
          >
            {templates.length > 0 &&
              templates.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {dataType && extras.length > 0 && (
          <Form.Item name="data_id" label={`Choose a ${dataType.name}`}>
            <Select
              showSearch
              placeholder={`Select a ${dataType.name} to proceed`}
              onSearch={handleDataSearch}
              filterOption={false}
            >
              {extras.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.fullname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

const HistoryList: FC<{ id: number | string; feedback: Feedback }> = ({ id, feedback }) => {
  const [page] = useState(1)
  const { data: historyData, error } = useSWR<IDataResponse<FeedbackHistory>>(
    serialize(FeedbackService.getHistory(id), {
      page,
      size: 75
    })
  )
  const [newHistoryRow, setNewHistoryRow] = useState<FeedbackHistory[]>([])
  const { sendMessage } = useWebSocket(FeedbackService.wsHistory, {
    onMessage: (event) => {
      const getJson = async () => {
        if (event?.data) {
          try {
            const blob = event.data as Blob
            const td = new TextDecoder('utf-8')
            const text = td.decode(await blob.arrayBuffer())
            const newData = JSON.parse(text) as FeedbackHistory
            if (newData) {
              var tmpDataRows = newHistoryRow.filter(
                (item) => item.id?.toString() !== newData.id?.toString()
              )
              setNewHistoryRow(
                [...tmpDataRows, newData].sort((a, b) => {
                  const dateA = new Date(a.created_at)
                  const dateB = new Date(b.created_at)
                  return dateA.getTime() - dateB.getTime()
                })
              )
            }
          } catch (e) {
            console.log('error when connecting to websocket')
            // setError(e)
          }
        }
      }
      getJson()
    },
    onError: (event) => {
      console.log('error when connecting to websocket')
      // setError('Failed to connected to websocket server')
    },
    reconnectInterval: 1000,
    reconnectAttempts: 120,
    retryOnError: true,
    onReconnectStop: (numAttempts) => {
      console.log(`stopped reconnecting to the server after ${numAttempts} attempts`)
    }
  })

  useEffect(() => {
    if (id) {
      sendMessage(JSON.stringify({ action: 'subscribe', id: id }))
      setNewHistoryRow([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const isFinalPage = useMemo(() => {
    if (historyData) {
      return historyData.meta?.current_page === historyData.meta?.last_page
    }
    return false
  }, [historyData])

  const bottomRef = useRef<HTMLDivElement>(null)
  let date = ''

  useEffect(() => {
    if (historyData || newHistoryRow) {
      bottomRef?.current && bottomRef.current.scrollIntoView()
    }
  }, [historyData, newHistoryRow])

  const histories = useMemo(() => {
    if (!historyData) return []
    // var rows: any[] = []
    // if (isFinalPage) rows = [feedback]
    return [...FeedbackService.historyToRow(historyData), ...newHistoryRow]
  }, [historyData, newHistoryRow, isFinalPage, feedback])

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!historyData) {
    return <Spin />
  }

  return (
    <>
      {histories.map((item) => {
        const today = new Date()
        const dateNow = new Date(item.created_at)
        const dateNowStr = dateNow.toLocaleDateString()
        const sameDate = dateNowStr === date
        const yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        date = dateNowStr
        const isToday =
          dateNow.getFullYear() === today.getFullYear() &&
          dateNow.getMonth() === today.getMonth() &&
          dateNow.getDate() === today.getDate()
        const isYesterday =
          dateNow.getFullYear() === yesterday.getFullYear() &&
          dateNow.getMonth() === yesterday.getMonth() &&
          dateNow.getDate() === yesterday.getDate()
        return (
          <div key={item.id}>
            {!sameDate && (
              <Flex
                sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', mb: 4 }}
                key={dateNowStr}
              >
                {isToday ? 'Today' : isYesterday ? 'Yesterday' : dateNowStr}
              </Flex>
            )}
            <DialogCell history={item} />
          </div>
        )
      })}
      <div ref={bottomRef}></div>
    </>
  )
}

const CustomerDetailsWrapper: FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: 'auto',
        overflowY: 'scroll',
        backgroundColor: 'white',
        p: 3
        // scrollbarWidth: 'none',
        // //for firefox
        // '::-webkit-scrollbar': {
        //   display: 'none'
        // }
      }}
    >
      <h3>Customer Details</h3>
      <Divider />
      <CustomerDetails />
      <Gaps />
      <CustomerOrders />
    </Box>
  )
}

const CustomerDetails: FC = () => {
  const { customerEmail: email } = useContext(feedbackContext)
  const { data, error } = useSWR<Customer>(email ? CustomerService.getByEmail(email) : null)

  if (!email) {
    return <></>
  }

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!data) {
    return <Spin />
  }

  return (
    <Card style={{ boxShadow: 'grey 3px 3px 6px', borderRadius: 10 }}>
      <Space direction="vertical">
        <Space style={{ fontWeight: 'bold' }}>{data.name}</Space>
        <Space>
          <PhoneOutlined />
          <Typography.Text>{data.phone}</Typography.Text>
        </Space>
        <Space>
          <MailOutlined />
          <Typography.Text>{data.email}</Typography.Text>
        </Space>
      </Space>
    </Card>
  )
}

const CustomerOrders: FC = () => {
  const { customerEmail: email } = useContext(feedbackContext)
  const [isCollapsed, setIscollapsed] = useState(true)
  const { data, error } = useSWR(
    email
      ? serialize(OrderService.getAll, {
          customer_email: email,
          limit: isCollapsed ? 5 : undefined
        })
      : null
  )

  if (!email) {
    return <></>
  }

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!data) {
    return <Spin />
  }

  const orders = OrderService.toRow(data)

  if (orders.length === 0) {
    return <></>
  }

  return (
    <Card style={{ boxShadow: 'grey 3px 3px 6px', borderRadius: 10 }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {orders.length < 0 ? (
          <Empty description="No record" />
        ) : (
          orders.map((item) => {
            const createdAt = new Date(item.created_at)
            return (
              <Card key={item.id}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Space>
                    <Typography.Text>#{item.id}</Typography.Text>
                    <Typography.Text>{createdAt.toLocaleString()}</Typography.Text>
                  </Space>
                  <Divider />
                  {item.line_items.map((lineItem) => (
                    <Card key={lineItem.id}>
                      {lineItem.name} X {lineItem.quantity}
                    </Card>
                  ))}
                </Space>
              </Card>
            )
          })
        )}
      </Space>
    </Card>
  )
}

const FeedbackCard: FC<{ feedback: Feedback; key?: any }> = ({ feedback }) => {
  const { id, setId } = useContext(feedbackContext)
  const cardRef = useRef<HTMLDivElement>(null)
  const today = new Date()
  const dateNow = new Date(feedback.updated_at)
  const dateNowStr = dateNow.toLocaleDateString()
  const timeStr = dateNow.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  })

  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  const isToday =
    dateNow.getFullYear() === today.getFullYear() &&
    dateNow.getMonth() === today.getMonth() &&
    dateNow.getDate() === today.getDate()
  const isYesterday =
    dateNow.getFullYear() === yesterday.getFullYear() &&
    dateNow.getMonth() === yesterday.getMonth() &&
    dateNow.getDate() === yesterday.getDate()

  useEffect(() => {
    if (id?.toString() === feedback.id.toString()) {
      cardRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Box
      onClick={() => {
        setId && setId(feedback.id)
      }}
      ref={cardRef}
    >
      <Box
        variant="layout.sidebarItem"
        sx={{
          bg: id?.toString() === feedback.id.toString() ? 'bg' : 'none',
          borderRadius: 20
        }}
      >
        <Text variant="mediumSmall">
          {feedback.first_name} {feedback.last_name} <br />
          {feedback.email}
        </Text>
        <Text variant="large">{feedback.title === '' ? '<No title>' : feedback.title}</Text>
        <Text color="grey" variant="mediumSmall">
          {isToday ? 'Today' : isYesterday ? 'Yesterday' : dateNowStr} {timeStr}
        </Text>
      </Box>
    </Box>
  )
}

export default FeedbackPage
