import { publicClient } from './../request'
import { Admin } from '../../lib/store/auth'
import client, { IDataResponse } from '../request'

export type Feedback = {
  id: number | string
  email: string
  first_name: string
  last_name: string
  content: string
  title?: string | null
  histories: FeedbackHistory[]
  uploads: FeedbackUpload[]
  created_at: string
  updated_at: string
}

export type CreateFeedback = {
  title?: string
  email: string
  first_name: string
  last_name: string
  content: string
}

export type CreateHistory = {
  content: string
}

export type FeedbackUpload = {
  id: number | string
  feedback_id: number | string
  feedback_history_id: number | string
  file_name: string
  platform_file_name: string
  client_file_name: string
  url: string
}

export type FeedbackHistory = {
  id: number | string
  feedback_id: number | string
  admin_id?: number | string | null
  admin?: Admin
  uploads: FeedbackUpload[]
  content: string
  created_at: string
  updated_at: string
}

const getAll = '/feedbacks'
const wsGetAll = process.env.REACT_APP_CS_WS_URL!

const get = (id: string | number) => {
  return `/feedbacks/${id}`
}

const getHistory = (id: string | number) => {
  return `/feedbacks/${id}/histories`
}

const wsHistory = process.env.REACT_APP_CS_HISTORY_WS_URL!

const create = (data: CreateFeedback) => {
  return publicClient.post(`/feedbacks`, data)
}

const createHistory = (id: string | number, data: CreateHistory | FormData) => {
  return client.post(`/feedbacks/${id}/histories`, data)
}

const customerCreateHistory = (id: string | number, data: CreateHistory, token: string) => {
  return publicClient.post(`/feedbacks/${id}`, data, {
    headers: {
      'x-customer-service-access-token': token
    }
  })
}

// processing
const toRow = (data: IDataResponse<Feedback>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const historyToRow = (data: IDataResponse<FeedbackHistory>) => {
  if (data?.data?.length > 0) {
    return data.data
      .map((element) => {
        return {
          ...element,
          key: element.id
        }
      })
      .reverse()
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<Feedback>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// processing
// const historyToPaginate = (data) => {
//   return {
//     total: data?.meta?.total ?? 0,
//   };
// };

const FeedbackService = {
  getAll,
  wsGetAll,
  get,
  getHistory,
  wsHistory,
  create,
  createHistory,
  customerCreateHistory,
  toRow,
  historyToRow,
  toPaginate
  //historyToPaginate,
}

export default FeedbackService
