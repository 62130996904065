export type TemplateData = {
  name: string
  description: string
  sample: string
}

export const CommonData: TemplateData[] = [
  {
    name: 'data.id',
    description: 'The ID of the spedific feedback',
    sample: '0001'
  },
  {
    name: 'data.email',
    description: 'The email address of the customer',
    sample: 'example@mail.com'
  },
  {
    name: 'data.firstName',
    description: 'The first name of the customer',
    sample: '太郎'
  },
  {
    name: 'data.lastName',
    description: 'The last name of the customer',
    sample: '中村'
  },
  {
    name: 'data.content',
    description: 'The description of the feedback',
    sample: 'Test description'
  },
  {
    name: 'data.title',
    description: 'The title of the feedback',
    sample: 'Test title'
  },
  {
    name: 'data.createdAt',
    description: 'The date which the feedback was created',
    sample: '0001'
  }
]

export const ProductData: TemplateData[] = [
  {
    name: 'product.fullname',
    description: 'The fullname of the product',
    sample: 'テストアイテム - ブラック/メタル'
  },
  {
    name: 'product.price',
    description: 'The price of the product',
    sample: '9,999'
  },
  {
    name: 'product.sku',
    description: 'The SKU of the product',
    sample: 'SKU001'
  },
  {
    name: 'product.sku',
    description: 'The SKU of the product',
    sample: 'SKU001'
  }
]

export const OrderData: TemplateData[] = [
  {
    name: 'order.id',
    description: 'The id of the order',
    sample: '0001'
  },
  {
    name: 'order.customer.phone',
    description: 'The phone number of the customer who made the order',
    sample: '080-123-4567'
  },
  {
    name: 'order.firstName',
    description: 'The first name of order receipiant',
    sample: '太郎'
  },
  {
    name: 'order.lastName',
    description: 'The first name of order receipiant',
    sample: '中村'
  },
  {
    name: 'order.items',
    description: 'The ordered items',
    sample: 'アイテム X 1, アイテム２ X 2'
  },
  {
    name: 'order.address',
    description: 'The address including street and house number',
    sample: 'XX県XX区X丁目1-2-3'
  },
  {
    name: 'order.apartment',
    description: 'The apartment, if any',
    sample: 'XXビル123'
  },
  {
    name: 'order.company',
    description: 'The company, if any',
    sample: '株式会社XX'
  },
  {
    name: 'order.statusName',
    description: 'current status of the order',
    sample: '出荷待ち'
  }
]

// export const FulfillmentData: TemplateData[] = [
//   {
//     name: 'product.fullname',
//     description: 'The fullname of the product',
//     sample: 'テストアイテム - ブラック/メタル'
//   },
//   {
//     name: 'product.price',
//     description: 'The price of the product',
//     sample: '9,999'
//   },
//   {
//     name: 'product.sku',
//     description: 'The SKU of the product',
//     sample: 'SKU001'
//   },
//   {
//     name: 'product.sku',
//     description: 'The SKU of the product',
//     sample: 'SKU001'
//   }
// ]
