import { DownloadOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { FC, ReactNode, useMemo, useState } from 'react'
import { Flex, Box, Grid, AspectRatio, Image } from 'theme-ui'
import { saveAs } from 'file-saver'
import { FeedbackHistory, Feedback } from '../network/services/feedback'
import axios from 'axios'
import ImageModal from './ImageModal'

const DialogCell: FC<{ history: FeedbackHistory | Feedback }> = ({ history }) => {
  const [imageModal, setImageModal] = useState(false)
  const [displayImageIndex, setDisplayImageIndex] = useState(0)
  const createdAt = useMemo(() => {
    const dateTime = new Date(history.created_at)
    return dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }, [history.created_at])

  const breakLineHistory: ReactNode[] = useMemo(() => {
    if (!history) return []
    const arr = history.content.split(/(?=[\n])|(?<=[\n])/g)
    var brArr: ReactNode[] = []
    for (var i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (item === '\n') {
        brArr.push(<br key={i} />)
        continue
      }
      brArr.push(item)
    }
    return brArr
  }, [history])

  const isAdmin = useMemo(() => {
    const anyHistory: any = history
    if (!anyHistory['feedback_id']) return false
    return !!anyHistory['admin_id']
  }, [history])

  const handleDispayOriginalImage = (index: number) => {
    setDisplayImageIndex(index)
    setImageModal(true)
  }

  const handleDownload = async (src: string, fileName: string) => {
    try {
      const { data } = await axios.get(src, {
        responseType: 'blob'
      })
      saveAs(data, fileName)
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <>
      <ImageModal
        initialIndex={displayImageIndex}
        isOpen={imageModal}
        onRequestClose={() => setImageModal(false)}
        files={history.uploads}
      />
      <Flex
        sx={{
          my: 3,
          width: '100%',
          alignItems: 'center',
          justifyContent: isAdmin ? 'flex-end' : 'flex-start'
        }}
      >
        <Box sx={{ maxWidth: '70%' }}>
          <Box
            sx={{
              p: 3,
              bg: isAdmin ? 'primary' : 'bg',
              maxWidth: '100%',
              borderRadius: isAdmin ? '20px 0px 20px 20px' : '0px 20px 20px 20px'
            }}
          >
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {breakLineHistory.map((item) => item)}
              {history.uploads.length > 0 && (
                <>
                  <Box p={2} />
                  <Grid columns={history.uploads.length > 1 ? 2 : 1}>
                    {history.uploads.slice(0, 4).map((item, index) => {
                      const slicedFileName = item.file_name.split('.')
                      const ext = slicedFileName[slicedFileName.length - 1]
                      const isImage = ['png', 'jpg', 'jfif', 'webp'].includes(ext.toLowerCase())
                      return (
                        <Box
                          sx={{
                            borderRadius: 10,
                            overflow: 'hidden',
                            width: history.uploads.length > 1 ? '15vh' : '30vh'
                          }}
                          key={item.id}
                        >
                          <AspectRatio ratio={1}>
                            {isImage ? (
                              index === 3 && history.uploads.length > 4 ? (
                                <Box
                                  sx={{
                                    backgroundImage: `url(${item.url})`,
                                    width: '100%',
                                    height: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleDispayOriginalImage(3)}
                                >
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor: 'black',
                                      opacity: 0.4,
                                      position: 'absolute'
                                    }}
                                  />
                                  <Flex
                                    sx={{
                                      position: 'relative',
                                      zIndex: 0,
                                      width: '100%',
                                      height: '100%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Typography.Title style={{ color: 'white', opacity: 1 }}>
                                      {history.uploads.length - 3}+
                                    </Typography.Title>
                                  </Flex>
                                </Box>
                              ) : (
                                <Image
                                  src={item.url}
                                  alt={item.url}
                                  sx={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleDispayOriginalImage(index)}
                                />
                              )
                            ) : index === 3 && history.uploads.length > 4 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleDispayOriginalImage(3)}
                              >
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'black',
                                    opacity: 0.4,
                                    position: 'absolute'
                                  }}
                                />
                                <Flex
                                  sx={{
                                    position: 'relative',
                                    zIndex: 0,
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Typography.Title style={{ color: 'white', opacity: 1 }}>
                                    {history.uploads.length - 3}+
                                  </Typography.Title>
                                </Flex>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  textAlign: 'center',
                                  p: 3,
                                  background: 'white',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleDownload(item.url, item.file_name)}
                              >
                                <Flex
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <Typography.Text>{item.client_file_name}</Typography.Text>
                                  <Box>
                                    <DownloadOutlined />
                                  </Box>
                                </Flex>
                              </Box>
                            )}
                          </AspectRatio>
                        </Box>
                      )
                    })}
                  </Grid>
                </>
              )}
            </Typography.Text>
          </Box>
          <Box
            style={{
              color: 'grey',
              fontSize: '12px',
              paddingLeft: !isAdmin ? 5 : 'auto',
              paddingRight: isAdmin ? 5 : 'auto',
              textAlign: isAdmin ? 'right' : 'left',
              width: '100%'
            }}
          >
            {createdAt}
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default DialogCell
