import { proxy } from 'valtio'

export type LoginResponseState = {
  token?: string
  profile?: User
}

export type Admin = {
  id: string | number
  user_id: string | number
  type: number
  created_at: string
  updated_at: string
  products_access: boolean
  reviews_access: boolean
  user_permissions_access: boolean
  users_access: boolean
  stocks_access: boolean
  events_access: boolean
  warehouses_access: boolean
  returns_access: boolean
  sources_access: boolean
  orders_access: boolean
  orders_edit_access: boolean
  customers_access: boolean
  blacklists_access: boolean
}

export type User = {
  id: string | number
  email: string
  created_at: string
  updated_at: string
  admin?: Admin
}

const authStore = proxy<LoginResponseState>({
  token: undefined,
  profile: undefined
})

export const checkAuthorization = () => {
  const token = getToken()
  const profile = getProfile()
  if (token != null) {
    login({ token, profile })
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile }: { token: string; profile: User }) => {
  if (token) {
    authStore.token = token
    authStore.profile = profile

    console.log(token)
    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
  } else {
    // login error
  }
}

export const logout = () => {
  clearToken()
  clearProfile()

  authStore.token = undefined
  authStore.profile = undefined
}

export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
    return null
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    if (profile) {
      return JSON.parse(profile)
    }
  } catch (err) {
    clearProfile()
    return null
  }
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export default authStore
