import client, { serialize } from './../request'
import { IDataResponse } from '../request'

export interface FeedbackTemplateType {
  id: number
  name: string
  model_name: string
}

export interface GetFeedbackTemplatePreview {
  data_id?: string | number
  feedback_id: string | number
}

export interface FeedbackTemplatePreview {
  preview: string
}

export interface FeedbackTemplate {
  id: number
  content: string
  title: string
  optional_properties: any
  template_type: FeedbackTemplateType
  template_type_id: number | string
  created_at: string
  updated_at: string
}

export interface CreateFeedbackTemplate {
  content: string
  title: string
  optional_properties: any
  template_type_id: number | string
}

//get all
const getAll = '/feedback-templates'
//get types
const getTypes = '/feedback-template-types'

//get by id
const get = (id: string | number) => {
  return `/feedback-templates/${id}`
}

const getPreview = (id: string | number, data: GetFeedbackTemplatePreview) => {
  return client.get<FeedbackTemplatePreview>(serialize(`/feedback-templates/${id}/preview`, data))
}

const create = (data: CreateFeedbackTemplate) => {
  return client.post(`/feedback-templates`, data)
}

const update = (id: string | number, data: CreateFeedbackTemplate) => {
  return client.put(`/feedback-templates/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/feedback-templates/${id}`)
}

const toRow = (data: IDataResponse<FeedbackTemplate>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<FeedbackTemplate>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const FeedbackTemplateService = {
  getAll,
  getTypes,
  get,
  getPreview,
  create,
  update,
  remove,
  toRow,
  toPaginate
}

export default FeedbackTemplateService
