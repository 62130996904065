import React, { FC, useEffect, useState } from 'react'
import { Input, Form, Row, Col, DatePicker, Spin, Button, Select, message } from 'antd'
import _ from 'lodash'
// import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker
const { Option } = Select

export type FilterColumn = {
  key: string | number
  name: string
  default_value?: string | number
  default?: boolean
}

const TableFilter: FC<{
  dropdowns: any[]
  columns: any[]
  setCurrentFilters: (currentFilters: any) => any
  hasDate: boolean
}> = ({
  dropdowns = [
    // {
    //   key: 'status',
    //   value: [
    //     { key: -1, name: 'All Status' },
    //     { key: 0, name: 'Pending' },
    //     { key: 1, name: 'Success' },
    //     { key: 2, name: 'Failed' },
    //     { key: 3, name: 'Rejected' }
    //   ]
    // }
  ],
  columns,
  setCurrentFilters = null,
  hasDate = false
}) => {
  const [form] = Form.useForm()
  const [key, setKey] = useState(null)
  //   const { t } = useTranslation('translation')

  const submit = (v: any) => {
    console.log(v)
    let filtering: any = {}
    if (key != null) {
      filtering[key] = v.search
    }

    if (key == null) {
      message.error('Please select search scope to proceed for searching')
      return
    }

    for (let dropdown of dropdowns) {
      // && v[dropdown.key] != -1
      if (v[dropdown.key] != null) {
        filtering[dropdown.key] = v[dropdown.key]
      }
    }

    if (v.date != null) {
      filtering['from_date'] = v.date[0].toISOString()
      filtering['to_date'] = v.date[1].toISOString()
    }

    console.log(filtering)
    if (filtering !== {} && setCurrentFilters != null) {
      setCurrentFilters(filtering)
      // tableStore.state.filters = filtering
      // tableStore.state.currentPage = 1
    }
  }

  //   const reset = (v) => {
  //     let filtering = {}
  //     for (let dropdown of dropdowns) {
  //       // && v[dropdown.key] != -1
  //       if (v[dropdown.key] != null) {
  //         filtering[dropdown.key] = v[dropdown.key]
  //       }
  //     }

  //     if (v.date != null) {
  //       filtering['from_date'] = v.date[0].toISOString()
  //       filtering['to_date'] = v.date[1].toISOString()
  //     }

  //     console.log(filtering)
  //     if (filtering !== {} && setCurrentFilters != null) {
  //       setCurrentFilters(filtering)
  //       // tableStore.state.filters = filtering
  //       // tableStore.state.currentPage = 1
  //     }
  //   }

  const [initial, setInitial] = useState<Record<string, any>>({})
  useEffect(() => {
    let initial: Record<string, any> = {
      search: ''
    }

    for (let dropdown of dropdowns) {
      if (dropdown.default != null) {
        initial[dropdown.key] = dropdown.default
      }
    }

    columns.forEach((item) => {
      if (item.default === true) {
        initial.search = item.default_value
        setKey(item.key)
      }
    })

    setInitial(initial)
    // submit(initial)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (initial == null) {
    return <Spin />
  }

  const clearFilters = () => {
    submit('')
    form.setFieldsValue({
      search: ''
    })
  }

  return (
    <Form form={form} onFinish={submit} initialValues={initial}>
      <Row gutter={[12, 12]} align="top" justify="end">
        <Col>
          <Button type="primary" onClick={clearFilters}>
            {/* {t('Clear filter')} */}
            Clear filter
          </Button>
        </Col>
        {dropdowns?.length > 0 &&
          dropdowns.map((dropdown, index) => {
            return (
              dropdown?.value?.length > 0 && (
                <Col key={index}>
                  <Form.Item name={dropdown.key}>
                    <Select
                      style={{ width: dropdown?.width ?? 100 }}
                      placeholder={dropdown.label}
                      onSelect={() => {
                        form.submit()
                      }}
                    >
                      {dropdown.value.map((d: any) => {
                        return (
                          <Option value={d.key} key={d.key}>
                            {d.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )
            )
          })}

        {hasDate && (
          <Col>
            <Form.Item name="date">
              <RangePicker />
            </Form.Item>
          </Col>
        )}

        {columns?.length > 0 && (
          <Col>
            <Form.Item name="search">
              <Input
                addonBefore={
                  <Select
                    style={{ width: 140 }}
                    onChange={(v) => setKey(v)}
                    placeholder="Search scope"
                    defaultValue={_.find(columns, { default: true })?.key}
                  >
                    {columns.map((column) => {
                      return (
                        <Option value={column.key} key={column.key}>
                          {column.name}
                        </Option>
                      )
                    })}
                  </Select>
                }
                allowClear
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Button type="primary" htmlType="submit">
            {/* {t('Search')} */}
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default TableFilter
