import { serialize } from './../request'
import { IDataResponse } from '../request'

export interface Customer {
  id: number
  email: string
  name: string
  phone: string
  created_at: string
  updated_at: string
}

//get all
const getAll = '/customers'

//get by id
const get = (id: string | number) => {
  return `/customers/${id}`
}

//get by email
const getByEmail = (email: string) => {
  return serialize('/customers/by-email', {
    email: email
  })
}

//get order of customer
const getOrder = (id: string | number) => {
  return `/customers/${id}/order`
}

const getProduct = (id: string | number) => {
  return `/customers/${id}/product`
}

const toRow = (data: IDataResponse<Customer>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Customer>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const CustomerService = {
  getAll,
  get,
  getByEmail,
  getOrder,
  getProduct,
  toRow,
  toPaginate
}

export default CustomerService
