import { Customer } from './customer'
import { IDataResponse } from './../request'
import commaNumber from 'comma-number'

export interface TransactionStatus {
  id: number
  status: string
  created_at: string
  updated_at: string
}

export interface OrderStatus {
  id: number
  status: string
  created_at: string
  updated_at: string
}

export interface ShopPlatform {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export interface LineItem {
  id: number
  key: null | number | string
  platform_line_item_id: string
  price: number
  line_price: number | null
  final_price: number | null
  final_line_price: number | null
  quantity: number
  sku: string
  grams: number | null
  vendor: string
  taxable: boolean
  properties: any[]
  featured_image: any | null
  image_url: string | null
  handle: string | null
  requires_shipping: boolean
  gift_card: boolean
  name: string
  product_title: string
  variant_title: string
  options_with_values: any | null
  fulfillment_service: any | null
  tax_lines: any[]
  price_set: any | null
  total_discount: number | null
  total_discount_set: number | null
  discount_allocations: number | null
  duties: any[]
  product_has_only_default_variant: boolean
  order_id: string
  created_at: string
  updated_at: string
  fulfillable_quantity: number | undefined
}

export interface Fulfillment {
  id: number
  name: string
  order_id: number
  receipt: any | null
  service: string | null
  fulfillment_status_id: number
  tracking_company_name: string
  tracking_number: string | null
  tracking_urls: string | null
  tracking_url: string | null
  created_at: string
  updated_at: string
  line_items: LineItem[]
}

export interface Order {
  id: number
  platform_order_id: string
  customer_id: number
  zip: string
  address: string
  apartment: string | null
  company: string | null
  tracking: string
  transaction_status_id: number
  generated: boolean
  created_at: string
  updated_at: string
  customer_memo: string | null
  operator_memo: string | null
  first_name: string
  last_name: string
  phone: string
  shop_platform_id: number
  fulfilled_at: string
  tracking_company_name: string
  tracking_url: string
  name: string
  cancelled_at: string | null
  order_status_id: number
  status: OrderStatus
  customer: Customer
  transaction_status: TransactionStatus
  shop_platform: ShopPlatform
  total_amount: number
  line_items: LineItem[]
}

// useSWR, return strings
const getAll = '/orders'

// useSWR, return strings
const get = (id: string | number) => {
  return `/orders/${id}`
}

const getProducts = (id: string | number) => {
  return `/orders/${id}/products`
}

const getTransactionStatuses = `/transaction-statusus`
const getFulfillmentStatuses = `/fulfillment-statusus`
const getOrderStatuses = `/order-statusus`

// added filter to ordercontroller, this is no longer needed
// const getByStatus = (id) => {
//   return `/order/status/${id}`
// }

// added filter to ordercontroller, this is no longer needed
// const getByTracking = (id) => {
//   return `order/tracking/${id}`
// }

const getBlacklists = (id: string | number) => {
  return `/orders/${id}/blacklists`
}

// processing
const toRow = (data: IDataResponse<Order>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      const dateCreated = new Date(element?.created_at)
      const dateFulfilled = element?.fulfilled_at ? new Date(element?.fulfilled_at) : ''
      return {
        ...element,
        customer_email: element?.customer?.email,
        customer_name: element?.customer?.name,
        transaction_status: element?.transaction_status?.status,
        status: element?.status?.status,
        created_at: dateCreated.toLocaleString(),
        address: element?.address + (element?.apartment ?? ''),
        key: element?.id,
        total_amount: '￥' + commaNumber(element?.total_amount),
        generated: element?.generated ? 'Yes' : 'No',
        shop_platform: element?.shop_platform?.name,
        fulfilled_at: dateFulfilled?.toLocaleString() ?? ''
      }
    })
  }

  return []
}

// processing
const transactionStatusToRow = (data: TransactionStatus[]) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        name: element?.status,
        key: element?.id
      }
    })
  }

  return []
}

// processing
const statusToRow = (data: OrderStatus[]) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        name: element?.status,
        key: element?.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<Order>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

//downloadCSV
// const download = () => {
//   return client.get(`/order/csv`)
// }

const OrderService = {
  getAll,
  get,
  getTransactionStatuses,
  getFulfillmentStatuses,
  getOrderStatuses,
  getBlacklists,
  statusToRow,
  getProducts,
  toPaginate,
  transactionStatusToRow,
  toRow
}

export default OrderService
